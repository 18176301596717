import "./DriverTripRow.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { SHORT_MONTH_DAY_YEAR, TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { CANCELLED_STATUS } from "../../../constants/tripStatus"
import SvgIcon from "../../atoms/SvgIcon"
import DriverTripDetails from "../../organisms/DriverTripDetails"

const DriverTripRow = ({ trip, scrollRef }) => {
  const [tripShouldFetch, setTripShouldFetch] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [animationRunning, setAnimationRunning] = useState(false)
  const [scrollRunning, setScrollRunning] = useState(false)
  const driverTripRowClass = classNames("driver-trip-row-container", {
    expanded,
    animationRunning,
  })
  const pnrClass = classNames("pnr-item", {
    past: trip?.is_past_trip,
    cancelled: trip?.status === CANCELLED_STATUS,
  })
  const ref = useRef()

  const executeScroll = () => {
    scrollRef.current.scrollTo({ top: ref.current.offsetTop - 60, left: 0, behavior: "smooth" })
  }

  useEffect(() => {
    if (scrollRunning) {
      setScrollRunning(false)
      executeScroll()
    }
  }, [scrollRunning])

  return (
    <div className={driverTripRowClass} ref={ref}>
      <div className="trip-row-grid">
        <div className="item-wrapper">
          <span className={pnrClass}>{trip.pnr}</span>
        </div>

        <div className="item-wrapper">
          <div className="icon-column-wrapper">
            <div className="icon-wrapper">
              <SvgIcon icon="calendar" width={10} height={10} />
            </div>

            <span className="text-span">
              {moment(trip.departure_time).format(SHORT_MONTH_DAY_YEAR)}
            </span>
          </div>
        </div>

        <div className="item-wrapper">
          <div className="icon-column-wrapper">
            <div className="icon-wrapper">
              <SvgIcon icon="clock" width={10} height={8} />
            </div>
            <span className="text-span">
              {moment(trip.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
            </span>
          </div>
        </div>

        <div className="item-wrapper">
          <span className="text-span">{trip.depart.iata}</span>
        </div>

        <div className="item-wrapper">
          <span className="text-span">{trip.arrive.iata}</span>
        </div>

        <div
          className="item-wrapper expand-button"
          role="button"
          onClick={() => {
            if (expanded) {
              setTripShouldFetch(false)
              setAnimationRunning(true)
              setTimeout(() => {
                setAnimationRunning(false)
              }, 500)
            } else {
              setTripShouldFetch(true)
              setScrollRunning(true)
            }
            setExpanded(!expanded)
          }}
        >
          <SvgIcon
            icon={expanded ? "expand-up-arrow" : "expand-down-arrow"}
            width={21}
            height={22}
          />
        </div>
      </div>

      <DriverTripDetails
        tripShouldFetch={tripShouldFetch}
        tripID={trip.id}
        isPastTrip={trip.is_past_trip}
      />
    </div>
  )
}

DriverTripRow.propTypes = {
  trip: PropTypes.instanceOf(Object),
  scrollRef: PropTypes.instanceOf(Object),
}

DriverTripRow.defaultProps = {
  trip: {},
  scrollRef: null,
}

export default DriverTripRow
