export default {
  // user actions
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  DECODE_JWT: "DECODE_JWT",
  REGISTER_USER: "REGISTER_USER",
  GET_USER_PROFILE: "GET_USER_PROFILE",

  // trip actions
  GET_COORDINATES: "GET_COORDINATES",
  GET_TRIPS: "GET_TRIPS",
  GET_TRIP_DETAILS: "GET_TRIP_DETAILS",
  GET_NEXT_TRIPS_PAGE: "GET_NEXT_TRIPS_PAGE",
  START_TRIP: "START_TRIP",
  COMPLETE_TRIP: "COMPLETE_TRIP",
}
