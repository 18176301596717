import "./ContactModal.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Modal from "../../organisms/Modal"
import Button from "../Button"
import SvgIcon from "../SvgIcon"

const ContactModal = ({ phoneNumber, isOpen, setIsOpen }) => {
  const formatPhoneNumber = number => {
    if (number.charAt(0) === "+") {
      return number.slice(1, number.length)
    }

    return number
  }

  const callButtonClass = classNames("call-button", {
    noNumber: !phoneNumber,
  })

  return (
    <Modal
      onClick={e => e.stopPropagation()}
      render={() => (
        <div className="contact-modal-container">
          <div
            className={callButtonClass}
            role="button"
            onClick={() => {
              if (phoneNumber) {
                window.open(`tel:+${formatPhoneNumber(phoneNumber)}`, "_self")
              }
            }}
          >
            {phoneNumber ? (
              <>
                <SvgIcon icon="phone-large" width={20} height={19} />
                <p className="call-text">Call +{formatPhoneNumber(phoneNumber)}</p>
              </>
            ) : (
              <p className="call-text">There is no contact number.</p>
            )}
          </div>

          <Button
            text="Cancel"
            fullWidth
            margin="10px 0px 0px 0px"
            onClick={() => setIsOpen(false)}
            buttonStyle="red"
          />
        </div>
      )}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          display: "flex",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 99999,
          overflow: "none",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingBottom: 12,
        },
        content: {
          width: "100%",
          display: "flex",
          overflow: "none",
          borderRadius: "4px",
          border: "none",
          alignItems: "center",
          height: "auto",
          padding: 0,
          backgroundColor: "unset",
          margin: "0px 13px",
        },
      }}
    />
  )
}

ContactModal.propTypes = {
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

ContactModal.defaultProps = {
  phoneNumber: "",
}

export default ContactModal
