import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  coordinates: {},
  tripDetails: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_COORDINATES_SUCCESS:
      return {
        ...state,
        coordinates: {
          ...state.coordinates,
          [action.tripID]: {
            ...state.coordinates[action.tripID],
            [action.field]: action.coordinates,
          },
        },
      }

    case ACTIONS.GET_TRIPS_SUCCESS:
      return {
        ...state,
        userTrips: action.trips,
      }

    case ACTIONS.GET_NEXT_TRIPS_PAGE_SUCCESS:
      return {
        ...state,
        userTrips: {
          ...state.userTrips,
          results: [...state.userTrips.results, ...action.trips.results],
          next: action.trips.next,
        },
      }

    case ACTIONS.START_TRIP_SUCCESS:
    case ACTIONS.COMPLETE_TRIP_SUCCESS:
    case ACTIONS.GET_TRIP_DETAILS_SUCCESS: {
      return {
        ...state,
        tripDetails: { ...state.tripDetails, [action.tripID]: action.tripDetails },
      }
    }

    case ACTIONS.RESET_TRIP_STATE_SUCCESS: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
