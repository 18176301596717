import "./App.scss"
import "./globals.scss"

import { createBrowserHistory } from "history"
import React from "react"
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from "react-router-dom"

import CustomerPage from "./components/pages/CustomerPage"
import DriverPage from "./components/pages/DriverPage"
import LoginPage from "./components/pages/LoginPage"
import Page404 from "./components/pages/Page404"
import RegisterPage from "./components/pages/RegisterPage"
import AuthenticatedRoute from "./components/routers/AuthenticatedRoute"
import GuestRoute from "./components/routers/GuestRoute"

export const history = createBrowserHistory()

const App = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          path="/"
          element={
            <AuthenticatedRoute driverElement={<DriverPage />} customerElement={<CustomerPage />} />
          }
        />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <LoginPage />
            </GuestRoute>
          }
        />
        <Route
          path="/register"
          element={
            <GuestRoute>
              <RegisterPage />
            </GuestRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </HistoryRouter>
  )
}

export default App
