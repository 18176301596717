import "./LoginPage.scss"

import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import API from "../../../constants/API"
import { resetTripState } from "../../../redux/trip/tripActions"
import { loginUser } from "../../../redux/user/userActions"
import apiUtils from "../../../utils/api/apiUtils"
import Button from "../../atoms/Button"
import InputField from "../../atoms/InputField"
import SocialNetworks from "../../atoms/SocialNetworks"
import SvgIcon from "../../atoms/SvgIcon"

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(true)
  const emailRef = useRef()
  const isChrome = !!window.chrome
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [formData, callsInProgress] = useSelector(({ forms, api }) => [
    forms.formData,
    api.callsInProgress,
  ])

  useEffect(() => {
    dispatch(resetTripState())
    if (emailRef.current && isChrome) {
      setTimeout(() => {
        if (emailRef.current.matches(":-internal-autofill-selected")) {
          setDisabled(false)
        }
      }, 100)
    }
  }, [])

  useEffect(() => {
    setDisabled(!formData.email || !formData.password)
  }, [formData.email, formData.password])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.LOGIN_USER], callsInProgress)
  }, [callsInProgress])

  return (
    <div className="login-container">
      <img
        className="background-vector-1"
        width={234}
        height={140}
        src="/media/background-vector-1.png"
        alt=""
      />

      <img
        className="background-vector-2"
        width={195}
        height={81}
        src="/media/background-vector-2.png"
        alt=""
      />

      <div className="login-content-container ">
        <div className="logo-container">
          <img className="logo" width={147} height={24} src="/media/landline.png" alt="" />
        </div>
        <InputField
          type="email"
          label="Email"
          placeholder="Enter email"
          inputStyle="primary"
          margin="0px 0px 0px 0px"
          value={formData.email}
          field="email"
          inputRef={emailRef}
        />
        <InputField
          type={passwordVisible ? "text" : "password"}
          label="Password"
          placeholder="Enter password"
          inputStyle="primary"
          margin="10px 0px 0px 0px"
          value={formData.password}
          field="password"
          icon={<SvgIcon icon={passwordVisible ? "eye-show" : "eye-hide"} />}
          onIconClick={() => setPasswordVisible(!passwordVisible)}
        />
        <Button
          disabled={disabled}
          text="Login"
          onClick={() => {
            dispatch(loginUser(navigate))
          }}
          buttonStyle="primary"
          margin="30px 0px 15px 0px"
          fullWidth
          loading={loading}
        />
        <div className="create-account-wrapper">
          <p>Don&apos;t have an account? </p>
          <div role="button" className="create-account-link" onClick={() => navigate("/register")}>
            Create Account
          </div>
        </div>
        <SocialNetworks />
      </div>
    </div>
  )
}

LoginPage.propTypes = {}

LoginPage.defaultProps = {}

export default LoginPage
