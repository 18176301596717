import "./CollapsibleContent.scss"

import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getTripDetails } from "../../../redux/trip/tripActions"
import CustomMap from "../CustomMap"
import DriverInformation from "../DriverInformation"
import Loading from "../Loading"

const CollapsibleContent = ({ tripID, tripShouldFetch }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (tripID && tripShouldFetch) {
      dispatch(getTripDetails(tripID))
    }
  }, [tripID, tripShouldFetch])

  const [tripDetails] = useSelector(({ trip }) => [trip.tripDetails[tripID]])

  if (!tripDetails) {
    return (
      <div className="collapsible-content-container">
        <Loading className="expandable-loading" />
      </div>
    )
  }

  return (
    <div className="collapsible-content-container">
      <CustomMap
        pickUpAddress={tripDetails?.pick_up_details?.pick_up_address}
        dropOffAddress={tripDetails?.pick_up_details?.drop_off_address}
        tripID={tripID}
      />
      <DriverInformation
        driverInfo={tripDetails?.driver_details}
        vehicleInfo={tripDetails?.vehicle_details}
      />
    </div>
  )
}

CollapsibleContent.propTypes = {
  tripID: PropTypes.number,
  tripShouldFetch: PropTypes.bool,
}

CollapsibleContent.defaultProps = {
  tripID: 0,
  tripShouldFetch: false,
}

export default CollapsibleContent
