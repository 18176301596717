import "./DriverPage.scss"

import moment from "moment"
import React, { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/appSettings"
import { MIDNIGHT_DATETIME } from "../../../constants/dateFormats"
import { getNextTripsPage, getTrips } from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import CalendarComponent from "../../atoms/CalendarComponent"
import DriverTripColumns from "../../atoms/DriverTripColumns"
import Loading from "../../atoms/Loading"
import RegularLayout from "../../layouts/RegularLayout"
import DriverTripRow from "../../molecules/DriverTripRow"

const DriverPage = () => {
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(0)
  const [chosenDate, setChosenDate] = useState(moment().format(MIDNIGHT_DATETIME))
  const scrollRef = useRef()

  const [trips, callsInProgress, userProfile] = useSelector(({ trip, api, user }) => [
    trip.userTrips,
    api.callsInProgress,
    user.userProfile,
  ])

  useEffect(() => {
    if (userProfile?.id) {
      setOffset(0)
      dispatch(getTrips({ departure_time__gte: chosenDate, driver: userProfile.id }))
    }
  }, [userProfile?.id, chosenDate])

  const tripBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIPS, API.GET_NEXT_TRIPS_PAGE], callsInProgress)
  }, [callsInProgress])

  const initialLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIPS], callsInProgress)
  }, [callsInProgress])

  const loadMore = () => {
    if (!tripBusy && userProfile?.id) {
      const currentOffset = offset + PAGE_LIMIT

      dispatch(
        getNextTripsPage({
          offset: currentOffset,
          departure_time__gte: chosenDate,
          driver: userProfile.id,
        }),
      )
      setOffset(currentOffset)
    }
  }

  const getPageContent = () =>
    trips && trips.results.length > 0 ? (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!!trips?.next}
        pageStart={0}
        loader={<Loading className="scroll" key={trips?.next} />}
        threshold={100}
        useWindow={false}
      >
        <div className="scroll-content-wrapper">
          <DriverTripColumns />

          {trips &&
            trips.results.map(trip => (
              <DriverTripRow trip={trip} key={trip.id} scrollRef={scrollRef} />
            ))}
        </div>
      </InfiniteScroll>
    ) : (
      <div className="empty-state-image-wrapper">
        <img className="empty-state-image" src="./media/driver-empty-state.svg" alt="Empty state" />
      </div>
    )

  return (
    <RegularLayout withTopMenu withLogout>
      <div className="driver-page-container" ref={scrollRef}>
        <div className="fixed-content">
          <CalendarComponent
            value={moment(chosenDate).toDate()}
            onChange={val => setChosenDate(moment(val).format(MIDNIGHT_DATETIME))}
          />

          <div className="breaking-line" />
        </div>
        {initialLoading || !userProfile?.id ? (
          <Loading className="page-content-loading" />
        ) : (
          getPageContent()
        )}
      </div>
    </RegularLayout>
  )
}

DriverPage.propTypes = {}

DriverPage.defaultProps = {}

export default DriverPage
