import "./CalendarComponent.scss"
import "react-calendar/dist/Calendar.css"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import Calendar from "react-calendar"

import { DAY_MONTH_YEAR } from "../../../constants/dateFormats"
import { useOnClickOutside } from "../../../utils/hooks"
import SvgIcon from "../SvgIcon"

const CalendarComponent = ({ value, onChange, activeStartDate }) => {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const ref = useRef()

  const handleOnChange = date => {
    onChange(date)
    setCalendarOpen(false)
  }

  const handleChevronClick = dateV => {
    handleOnChange(value.setDate(value.getDate() + dateV))
  }

  useOnClickOutside(ref, () => setCalendarOpen(false))

  return (
    <div className="calendar-container" ref={ref}>
      <div
        className="calendar-date-picker"
        role="button"
        onClick={() => setCalendarOpen(!calendarOpen)}
      >
        <div
          className="icon-wrapper"
          role="button"
          onClick={e => {
            e.stopPropagation()
            handleChevronClick(-1)
          }}
        >
          <SvgIcon icon="chevron-left" width={14} height={14} />
        </div>
        <div className="date-picker-text disable-select">
          {moment(value).format(DAY_MONTH_YEAR)}
        </div>
        <div
          className="icon-wrapper"
          role="button"
          onClick={e => {
            e.stopPropagation()
            handleChevronClick(1)
          }}
        >
          <SvgIcon icon="chevron-right" width={14} height={14} />
        </div>
      </div>

      {calendarOpen && (
        <div className="calendar-wrapper">
          <Calendar
            value={value}
            formatShortWeekday={(locale, dateV) =>
              ["Sun", "M", "T", "W", "T", "F", "Sat"][dateV.getDay()]
            }
            formatMonth={(locale, dateV) => moment(dateV).format("MMM")}
            onChange={handleOnChange}
            view="month"
            className="react-calendar driver-status-modal"
            defaultActiveStartDate={activeStartDate}
            onClickYear={() => {}}
          />
        </div>
      )}
    </div>
  )
}

CalendarComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  onChange: PropTypes.func,
  activeStartDate: PropTypes.string,
}

CalendarComponent.defaultProps = {
  onChange: () => {},
  activeStartDate: "",
}

export default CalendarComponent
