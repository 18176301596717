import "./Logout.scss"

import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { logoutUser } from "../../../redux/user/userActions"
import SvgIcon from "../SvgIcon"

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div
      className="logout-container"
      role="button"
      onClick={() => {
        dispatch(logoutUser(navigate))
      }}
    >
      <div className="logout-content-wrapper">
        <SvgIcon icon="logout" width={14} height={14} margin="0px 7px 0px 0px" fill="#ffffff" />
        <p className="logout-text">Logout</p>
      </div>
    </div>
  )
}

Logout.propTypes = {}

Logout.defaultProps = {}

export default Logout
