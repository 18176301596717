import "./CustomMap.scss"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getCoordinates } from "../../../redux/trip/tripActions"
import { isEmptyObject } from "../../../utils/common"
import config from "../../../utils/config"
import { formatAddress } from "../../../utils/tripUtils"

const CustomMap = ({ pickUpAddress, dropOffAddress, tripID }) => {
  const dispatch = useDispatch()
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
  })

  const [trips] = useSelector(({ trip }) => [trip])
  const [loading, setLoading] = useState(true)

  useEffect(() => {}, [])

  const [map, setMap] = useState()

  const onLoad = React.useCallback(function callback(mapp) {
    setMap(mapp)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  useEffect(() => {
    if (isLoaded && !isEmptyObject(pickUpAddress) && !isEmptyObject(dropOffAddress)) {
      dispatch(getCoordinates(formatAddress(pickUpAddress), "depart", tripID))
      dispatch(getCoordinates(formatAddress(dropOffAddress), "arrive", tripID))
      setLoading(false)
    }
  }, [isLoaded, pickUpAddress, dropOffAddress])

  useEffect(() => {
    if (isLoaded && map) {
      const { google } = window

      const locationsArr =
        trips.coordinates[tripID]?.depart && trips.coordinates[tripID]?.arrive
          ? [trips.coordinates[tripID]?.depart, trips.coordinates[tripID]?.arrive]
          : [{ lat: 46.21506817369399, lng: -94.89001725625972 }]

      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < locationsArr.length; i += 1) {
        bounds.extend(locationsArr[i])
      }

      map.fitBounds(bounds)
    }
  }, [isLoaded, trips.coordinates[tripID]?.depart, trips.coordinates[tripID]?.arrive, map])

  if (loading) return <div className="custom-map-container" />

  if (loadError) return "Error"

  const markersArr =
    trips.coordinates[tripID]?.depart && trips.coordinates[tripID]?.arrive
      ? [trips.coordinates[tripID]?.depart, trips.coordinates[tripID]?.arrive]
      : [
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
        ]

  return (
    <div role="button" className="custom-map-container" onClick={e => e.stopPropagation()}>
      <GoogleMap
        className="google-map"
        mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "8px" }}
        zoom={10}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
        }}
        onUnmount={onUnmount}
        onLoad={onLoad}
      >
        {markersArr.map((marker, index) => {
          return (
            <Marker
              icon={{
                url: index === 0 ? "./media/start-map-marker.svg" : "./media/end-map-marker.svg",
              }}
              position={{ ...marker }}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          )
        })}
      </GoogleMap>
    </div>
  )
}

CustomMap.propTypes = {
  pickUpAddress: PropTypes.instanceOf(Object),
  dropOffAddress: PropTypes.instanceOf(Object),
  tripID: PropTypes.number,
}

CustomMap.defaultProps = {
  pickUpAddress: {},
  dropOffAddress: {},
  tripID: 0,
}

export default CustomMap
