import "./DriverTripColumns.scss"

import React from "react"

const DriverTripColumns = () => {
  return (
    <div className="driver-trip-columns-container">
      <div className="driver-trip-column">PNR</div>
      <div className="driver-trip-column">D.DATE</div>
      <div className="driver-trip-column">D.TIME</div>
      <div className="driver-trip-column">D.ARP</div>
      <div className="driver-trip-column">A.ARP</div>
    </div>
  )
}

DriverTripColumns.propTypes = {}

DriverTripColumns.defaultProps = {}

export default DriverTripColumns
