import "./RegularLayout.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { decodeJWT, getUserProfile } from "../../../redux/user/userActions"
import Logout from "../../atoms/Logout"
import TopBar from "../../atoms/TopBar"

const RegularLayout = ({ children, withTopMenu, backgroundColor, withLogout }) => {
  const dispatch = useDispatch()

  const regularLayoutClass = classNames("regular-layout-container", {
    withTopMenu,
    withLogout,
  })

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(decodeJWT())
  }, [])

  const [userProfile, userRole] = useSelector(({ user }) => [user.userProfile, user.jwtData?.role])

  return (
    <div className={regularLayoutClass} style={{ backgroundColor }}>
      {withTopMenu && <TopBar userProfile={userProfile} userRole={userRole} />}
      <div className="regular-layout-content">{children}</div>
      {withLogout && <Logout />}
    </div>
  )
}

RegularLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]).isRequired,
  withTopMenu: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withLogout: PropTypes.bool,
}

RegularLayout.defaultProps = {
  withTopMenu: false,
  backgroundColor: "",
  withLogout: false,
}

export default RegularLayout
