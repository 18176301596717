import "./TopBar.scss"

import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import API from "../../../constants/API"
import { LANDLINE_PHONE_NUMBER } from "../../../constants/landlineContact"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import apiUtils from "../../../utils/api/apiUtils"
import ContactModal from "../ContactModal"
import Loading from "../Loading"
import SvgIcon from "../SvgIcon"
import UserAvatarName from "../UserAvatarName"

const TopBar = ({ userProfile, userRole }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const isEmpty = Object.keys(userProfile).length === 0

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const isDriver =
    userRole === COMPANY_DRIVER || +sessionStorage.getItem("userRole") === COMPANY_DRIVER

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.LOGIN_USER], callsInProgress)
  }, [callsInProgress])

  return (
    <div className="top-bar-container">
      {userProfile && !isEmpty && !loading ? (
        <UserAvatarName
          avatar={userProfile.avatar}
          firstName={userProfile.first_name}
          lastName={userProfile.last_name}
          userRole={userRole}
        />
      ) : (
        <Loading className="user-loading" />
      )}

      <div className="contact-us-wrapper" role="button" onClick={() => setContactModalOpen(true)}>
        <SvgIcon icon="phone" width={10} height={10} margin="0px 8px 0px 0px" />
        <p className="contact-us-text">Contact {isDriver ? "SOC" : "Us"}</p>
      </div>

      <ContactModal
        isOpen={contactModalOpen}
        setIsOpen={setContactModalOpen}
        phoneNumber={LANDLINE_PHONE_NUMBER}
      />
    </div>
  )
}

TopBar.propTypes = {
  userProfile: PropTypes.instanceOf(Object),
  userRole: PropTypes.number.isRequired,
}

TopBar.defaultProps = {
  userProfile: {},
}

export default TopBar
