import "./WarningSign.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

const WarningSign = ({ text }) => {
  const [popupVisible, setPopupVisible] = useState(false)
  return (
    <div className="warning-sign-container">
      <div
        role="button"
        className="warning-sign"
        onMouseEnter={() => setPopupVisible(true)}
        onMouseLeave={() => setPopupVisible(false)}
        onClick={() => {}}
      >
        <p className="exclamation-mark">!</p>
      </div>
      {popupVisible && <p className="warning-text">{text}</p>}
    </div>
  )
}

WarningSign.propTypes = {
  text: PropTypes.string,
}

WarningSign.defaultProps = {
  text: "",
}

export default WarningSign
