import "./UserAvatarName.scss"

import PropTypes from "prop-types"
import React from "react"

import { COMPANY_DRIVER, LANDLINE_USER } from "../../../constants/userRoles"
import { getFirstChar } from "../../../utils/common"

const UserAvatarName = ({
  firstName,
  lastName,
  avatar,
  userRole,
  avatarSize,
  avatarFontSize,
  nameFontStyle,
  isDriverInfo,
}) => {
  const usernameElement = name => {
    return (
      <p className="user-name" style={nameFontStyle}>
        {name}
      </p>
    )
  }

  return (
    <div className="avatar-name-container">
      {avatar ? (
        <img src={avatar} width={avatarSize} height={avatarSize} alt="Avatar" />
      ) : (
        <div className="default-avatar" style={{ width: avatarSize, height: avatarSize }}>
          <p className="default-avatar-text" style={{ fontSize: avatarFontSize }}>{`${getFirstChar(
            firstName,
          )}${getFirstChar(lastName)}`}</p>
        </div>
      )}

      <div className="user-name-role-wrapper">
        {userRole === LANDLINE_USER && firstName && usernameElement(firstName)}
        {userRole === LANDLINE_USER && firstName && usernameElement(lastName)}
        {(userRole === COMPANY_DRIVER || isDriverInfo) &&
          firstName &&
          usernameElement(`${firstName} ${lastName}`)}
        {userRole === COMPANY_DRIVER && <p className="user-role">Driver</p>}
      </div>
    </div>
  )
}

UserAvatarName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  userRole: PropTypes.number,
  avatarSize: PropTypes.number,
  avatarFontSize: PropTypes.number,
  nameFontStyle: PropTypes.instanceOf(Object),
  isDriverInfo: PropTypes.bool,
}

UserAvatarName.defaultProps = {
  firstName: "",
  lastName: "",
  avatar: "",
  userRole: 0,
  avatarSize: 37,
  avatarFontSize: 15,
  nameFontStyle: {},
  isDriverInfo: false,
}

export default UserAvatarName
