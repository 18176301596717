import queryString from "query-string"
import { toast } from "react-toastify"
import { getGeocode, getLatLng } from "use-places-autocomplete"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getCoordinates = (address, field, tripID) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_COORDINATES))

      const response = await getGeocode({ address })
      const { lng, lat } = await getLatLng(response[0])

      dispatch({
        type: ACTIONS.GET_COORDINATES_SUCCESS,
        coordinates: {
          lng,
          lat,
        },
        field,
        tripID,
      })
    } catch (error) {
      dispatch(apiError(API.GET_COORDINATES, error))
    }
  }
}

const getTrips = queryParams => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIPS))
      const urlQuery = queryString.stringify(queryParams)
      const trips = await landlineApi.get(`v2/bossadmin/trips/?${urlQuery}`)
      dispatch({
        type: ACTIONS.GET_TRIPS_SUCCESS,
        trips: trips.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_TRIPS, error))
    }
  }
}

const getNextTripsPage = queryParams => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_NEXT_TRIPS_PAGE))
      const urlQuery = queryString.stringify(queryParams)

      const trips = await landlineApi.get(`v2/bossadmin/trips/?${urlQuery}`)
      dispatch({
        type: ACTIONS.GET_NEXT_TRIPS_PAGE_SUCCESS,
        trips: trips.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_NEXT_TRIPS_PAGE, error))
    }
  }
}

const getTripDetails = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_DETAILS))
      const trip = await landlineApi.get(`v2/bossadmin/trips/${tripID}/`)

      dispatch({ type: ACTIONS.GET_TRIP_DETAILS_SUCCESS, tripID, tripDetails: trip.data })

      return trip
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_DETAILS, error))
      return false
    }
  }
}

const startTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.START_TRIP))
      const trip = await landlineApi.post(`v2/bossadmin/trips/${tripID}/start_ride/`)

      dispatch({ type: ACTIONS.START_TRIP_SUCCESS, tripID, tripDetails: trip.data })

      toast.success("Trip started successfully.")
    } catch (error) {
      dispatch(apiError(API.START_TRIP, error))
    }
  }
}

const completeTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.COMPLETE_TRIP))
      const trip = await landlineApi.post(`v2/bossadmin/trips/${tripID}/end_ride/`)

      dispatch({ type: ACTIONS.START_TRIP_SUCCESS, tripID, tripDetails: trip.data })

      toast.success("Trip completed successfully.")
    } catch (error) {
      dispatch(apiError(API.COMPLETE_TRIP, error))
    }
  }
}

const resetTripState = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_TRIP_STATE_SUCCESS })
  }
}

export {
  completeTrip,
  getCoordinates,
  getNextTripsPage,
  getTripDetails,
  getTrips,
  resetTripState,
  startTrip,
}
