import "./SocialNetworks.scss"

import React from "react"

import {
  LANDLINE_FACEBOOK,
  LANDLINE_INSTAGRAM,
  LANDLINE_TWITTER,
} from "../../../constants/landlineExternalUrls"
import SvgIcon from "../SvgIcon"

const SocialNetworks = () => {
  const handleThumbnailClick = url => {
    window.open(url, "_blank").focus()
  }

  return (
    <div className="social-networks-container">
      <div
        className="thumbnail-wrapper"
        role="button"
        onClick={() => handleThumbnailClick(LANDLINE_FACEBOOK)}
      >
        <SvgIcon icon="facebook" width={21} height={21} />
      </div>
      <div
        className="thumbnail-wrapper"
        role="button"
        onClick={() => handleThumbnailClick(LANDLINE_INSTAGRAM)}
      >
        <SvgIcon icon="instagram" width={21} height={21} />
      </div>
      <div
        className="thumbnail-wrapper"
        role="button"
        onClick={() => handleThumbnailClick(LANDLINE_TWITTER)}
      >
        <SvgIcon icon="twitter" width={21} height={21} />
      </div>
    </div>
  )
}

SocialNetworks.propTypes = {}

SocialNetworks.defaultProps = {}

export default SocialNetworks
