import "./CustomerTripRow.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { CANCELLED_STATUS } from "../../../constants/tripStatus"
import CollapsibleContent from "../../atoms/CollapsibleContent"
import VerticalLegConnection from "../../atoms/VerticalLegConnection"

const CustomerTripRow = ({ trip, scrollRef }) => {
  const [tripShouldFetch, setTripShouldFetch] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [animationRunning, setAnimationRunning] = useState(false)
  const [scrollRunning, setScrollRunning] = useState(false)
  const customerTripRowClass = classNames("customer-trip-row-container", {
    expanded,
    animationRunning,
  })
  const pnrTitleClass = classNames("pnr-title", {
    past: trip?.is_past_trip,
    cancelled: trip?.status === CANCELLED_STATUS,
  })

  const ref = useRef()

  const executeScroll = () => {
    scrollRef.current.scrollTo({ top: ref.current.offsetTop - 90, left: 0, behavior: "smooth" })
  }

  useEffect(() => {
    if (scrollRunning) {
      setScrollRunning(false)
      executeScroll()
    }
  }, [scrollRunning])

  return (
    <div
      className={customerTripRowClass}
      role="button"
      onClick={() => {
        if (expanded) {
          setTripShouldFetch(false)
          setAnimationRunning(true)
          setTimeout(() => {
            setAnimationRunning(false)
          }, 500)
        } else {
          setTripShouldFetch(true)
          setScrollRunning(true)
        }
        setExpanded(!expanded)
      }}
      ref={ref}
    >
      <h2 className={pnrTitleClass}>{trip.pnr}</h2>
      <VerticalLegConnection tripDetails={trip} expanded={expanded} />
      <CollapsibleContent tripShouldFetch={tripShouldFetch} tripID={trip.id} />
    </div>
  )
}

CustomerTripRow.propTypes = {
  trip: PropTypes.instanceOf(Object),
  scrollRef: PropTypes.instanceOf(Object),
}

CustomerTripRow.defaultProps = {
  trip: {},
  scrollRef: null,
}

export default CustomerTripRow
