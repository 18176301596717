import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Loading from "../Loading"

const Button = ({
  onClick,
  text,
  fullWidth,
  disabled,
  margin,
  buttonStyle,
  loading,
  sameHeight,
}) => {
  const buttonClass = classNames("button-container", {
    [buttonStyle]: buttonStyle,
    fullWidth,
    disabled,
    loading,
    sameHeight,
  })

  return (
    <div className={buttonClass} style={{ margin }}>
      <button disabled={disabled || loading} type="button" onClick={onClick}>
        {loading ? <Loading buttonLoading /> : text}
      </button>
    </div>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  buttonStyle: PropTypes.oneOf(["primary", "secondary", "red"]),
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  sameHeight: PropTypes.bool,
}

Button.defaultProps = {
  buttonStyle: "primary",
  fullWidth: false,
  margin: "",
  disabled: false,
  loading: false,
  sameHeight: false,
}

export default Button
