import "./index.scss"
import "react-toastify/dist/ReactToastify.css"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"

import App from "./App"
import { store } from "./redux/store"

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
)
