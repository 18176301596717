import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"

import { COMPANY_DRIVER, LANDLINE_USER } from "../../../constants/userRoles"
import { decodeJWT } from "../../../redux/actions"

const AuthenticatedRoute = ({ driverElement, customerElement }) => {
  const dispatch = useDispatch()

  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])
  const [component, setComponent] = useState(null)

  useEffect(() => {
    dispatch(decodeJWT())
  }, [])

  useEffect(() => {
    if (userRole === COMPANY_DRIVER || +sessionStorage.getItem("userRole") === COMPANY_DRIVER) {
      setComponent(driverElement)
    }

    if (userRole === LANDLINE_USER || +sessionStorage.getItem("userRole") === LANDLINE_USER) {
      setComponent(customerElement)
    }
  }, [userRole])

  const checkToken = () => {
    const accessToken = localStorage.getItem("accessToken")
    if (!accessToken) {
      if (sessionStorage.getItem("userRole"))
        toast.warning("You must login first.", { toastId: "login-message" })
      return <Navigate to="/login" />
    }
    if (userRole) {
      sessionStorage.setItem("userRole", userRole)
    }
    return component
  }

  return checkToken()
}

AuthenticatedRoute.propTypes = {
  driverElement: PropTypes.node.isRequired,
  customerElement: PropTypes.node.isRequired,
}

export default AuthenticatedRoute
