import "./DriverTripDetails.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { CANCELLED_STATUS } from "../../../constants/tripStatus"
import { completeTrip, getTripDetails, startTrip } from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import Button from "../../atoms/Button"
import InputField from "../../atoms/InputField"
import Loading from "../../atoms/Loading"
import SvgIcon from "../../atoms/SvgIcon"

const DriverTripDetails = ({ tripShouldFetch, tripID, isPastTrip }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (tripShouldFetch) {
      dispatch(getTripDetails(tripID))
    }
  }, [tripID, tripShouldFetch])

  const [tripDetails, callsInProgress] = useSelector(({ trip, api }) => [
    trip.tripDetails[tripID],
    api.callsInProgress,
  ])

  const startTripLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.START_TRIP], callsInProgress)
  }, [callsInProgress])

  const completeTripLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.START_TRIP], callsInProgress)
  }, [callsInProgress])

  const handleStartRide = () => {
    dispatch(startTrip(tripID))
  }

  const handleCompleteRide = () => {
    dispatch(completeTrip(tripID))
  }

  const formatAddress = (address, city, isAirport) => {
    let newAddress

    if (address && address !== "/") {
      newAddress = address
    }

    if (city && city !== "/") {
      if (newAddress) {
        newAddress += `, ${city}`
      } else {
        newAddress = city
      }
    }

    if (isAirport) {
      if (newAddress) {
        newAddress += ", Airport"
      } else {
        newAddress = "Airport"
      }
    }

    return newAddress || "/"
  }

  const getRideButton = () => {
    if (isPastTrip || tripDetails?.status === CANCELLED_STATUS) {
      return null
    }

    if (!tripDetails?.ride) {
      return (
        <Button
          buttonStyle="green"
          fullWidth
          text="Start trip"
          margin="0px 0px 32px 0px"
          onClick={handleStartRide}
          loading={startTripLoading}
          sameHeight
        />
      )
    }

    if (tripDetails.ride.start_date_time && !tripDetails.ride.end_date_time) {
      return (
        <Button
          buttonStyle="red"
          fullWidth
          text="Complete trip"
          margin="0px 0px 32px 0px"
          onClick={handleCompleteRide}
          loading={completeTripLoading}
          sameHeight
        />
      )
    }

    return null
  }

  const getTimeDifferenceBox = (tripTime, eventTime) => {
    if (!eventTime) {
      return null
    }

    const tripMoment = moment(tripTime)
    const eventMoment = moment(eventTime)

    const isRedBox = tripMoment.isBefore(eventMoment)

    const tripTimeBoxClass = classNames("trip-time-box", { red: isRedBox })

    const duration = moment.duration(tripMoment.diff(eventMoment))
    const minutes = duration.asMinutes()

    return (
      <div className={tripTimeBoxClass}>{`${isRedBox ? "+" : "-"}${Math.abs(
        Math.floor(minutes),
      )}`}</div>
    )
  }

  if (!tripDetails) {
    return (
      <div className="driver-trip-details-container">
        <Loading className="expandable-loading" />
      </div>
    )
  }

  return (
    <div className="driver-trip-details-container">
      <div className="segment-wrapper">
        <div className="segment-wrapper-side">
          <SvgIcon icon="black-car-forward" width={24} height={12} margin="2px 0px 0px 0px" />
          <p className="address-segment-text">Pick up address</p>
          {tripDetails?.ride?.start_date_time && !tripDetails?.ride?.end_date_time && (
            <p className="trip-status">Trip departed</p>
          )}
        </div>

        <div className="segment-wrapper-side">
          <SvgIcon icon="green-clock" width={10} height={10} margin="0px 2px 0px 0px" />
          <p className="trip-time">
            {moment(tripDetails?.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
          </p>
          {getTimeDifferenceBox(tripDetails?.departure_time, tripDetails?.ride?.start_date_time)}
        </div>
      </div>
      <InputField
        value={formatAddress(
          tripDetails?.pick_up_details?.pick_up_address?.first_address_line,
          tripDetails?.pick_up_details?.pick_up_address?.city,
          tripDetails?.depart.iata === "MSP",
        )}
        inputStyle="secondary"
        margin="5px 0px 22px 0px"
        isPreview
      />

      <div className="segment-wrapper drop-off">
        <div className="segment-wrapper-side">
          <SvgIcon icon="black-car-backward" width={24} height={12} margin="2px 0px 0px 0px" />
          <p className="address-segment-text">Drop off address</p>
          {tripDetails?.ride?.end_date_time && <p className="trip-status">Trip arrived</p>}
        </div>

        <div className="segment-wrapper-side">
          <SvgIcon icon="green-clock" width={10} height={10} margin="0px 2px 0px 0px" />
          <p className="trip-time">
            {moment(tripDetails?.arrival_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
          </p>
          {getTimeDifferenceBox(tripDetails?.arrival_time, tripDetails?.ride?.end_date_time)}
        </div>
      </div>
      <InputField
        value={formatAddress(
          tripDetails?.pick_up_details?.drop_off_address?.first_address_line,
          tripDetails?.pick_up_details?.drop_off_address?.city,
          tripDetails?.arrive.iata === "MSP",
        )}
        inputStyle="secondary"
        margin="5px 0px 25px 0px"
        isPreview
      />

      <div>
        <p className="passenger-details">Passenger details</p>
        <div className="number-of-passengers-wrapper">
          <p className="number-of-passengers-text">Number of passengers</p>
          <div className="number-of-passengers">{`0${tripDetails?.number_of_passengers}`}</div>
        </div>
      </div>

      <div>
        <div className="input-field-pair">
          <InputField
            label="Holder passenger first name"
            inputStyle="secondary"
            margin="0px 4px 0px 0px"
            value={tripDetails?.holder_passenger?.first_name}
            isPreview
          />
          <InputField
            label="Holder passenger last name"
            inputStyle="secondary"
            margin="0px 0px 0px 4px"
            value={tripDetails?.holder_passenger?.last_name}
            isPreview
          />
        </div>

        <InputField
          label="Phone number"
          inputStyle="secondary"
          value={tripDetails?.holder_passenger?.phone_number}
          isPreview
        />
        <InputField
          label="Email"
          inputStyle="secondary"
          value={tripDetails?.holder_passenger?.email}
          margin="10px 0px 26px 0px"
          isPreview
        />
      </div>

      {getRideButton()}
    </div>
  )
}

DriverTripDetails.propTypes = {
  tripID: PropTypes.number,
  tripShouldFetch: PropTypes.bool,
  isPastTrip: PropTypes.bool,
}

DriverTripDetails.defaultProps = {
  tripID: false,
  tripShouldFetch: -1,
  isPastTrip: false,
}

export default DriverTripDetails
