import "./VehicleInformation.scss"

import PropTypes from "prop-types"
import React from "react"

const VehicleInformation = ({ vehicleInfo }) => {
  return (
    <div className="vehicle-information-container">
      <div className="vehicle-avatar-model-wrapper">
        <img
          src={vehicleInfo.vehicle_image || "./media/default-car.png"}
          width={39}
          height={26}
          alt="Vehicle"
        />

        <div className="vehicle-model-wrapper">
          <p className="vehicle-model">{vehicleInfo.vehicle_model}</p>
          <p className="vehicle-year">{vehicleInfo.production_year}</p>
        </div>
      </div>
      <div className="vehicle-color-wrapper">
        <p className="vehicle-model">Vehicle color:</p>
        <div className="color-box" style={{ color: vehicleInfo.vehicle_color || "#3c3835" }} />
      </div>
    </div>
  )
}

VehicleInformation.propTypes = {
  vehicleInfo: PropTypes.instanceOf(Object),
}

VehicleInformation.defaultProps = {
  vehicleInfo: {},
}

export default VehicleInformation
