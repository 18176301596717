import "./VerticalLegConnection.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import {
  SHORT_MONTH_DAY_YEAR_WITH_COMMA,
  TWELVE_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import SvgIcon from "../SvgIcon"

const VerticalLegConnection = ({ tripDetails, expanded }) => {
  const [extendedTripDetails] = useSelector(({ trip }) => [trip.tripDetails[tripDetails.id]])

  return (
    <div className="vertical-leg-connection-container">
      <div className="location-row from">
        <div className="side-wrapper">
          <div className="location-icon-wrapper">
            <SvgIcon icon="location-point" height={16} width={15} />
            <div className="connection-line" />
          </div>

          <div>
            <p className="bold-text">
              {expanded && extendedTripDetails ? `${tripDetails.depart.AirportName}` : "From"}
            </p>
            <p className="gray-text">
              {expanded && extendedTripDetails
                ? `${extendedTripDetails?.pick_up_details?.pick_up_address?.first_address_line}`
                : `${tripDetails.depart.AirportName}`}
            </p>
            {expanded && extendedTripDetails && (
              <p className="gray-text">
                {extendedTripDetails?.pick_up_details?.pick_up_address?.city}
              </p>
            )}
          </div>
        </div>

        <div className="right-side-wrapper">
          <p className="bold-text">
            {moment(tripDetails.departure_time).format(SHORT_MONTH_DAY_YEAR_WITH_COMMA)}
          </p>
          <div className="time-wrapper">
            <SvgIcon icon="green-clock" width={9} height={10} margin="0 3px 0 0" fill="green" />
            <p className="green-text">
              {moment(tripDetails.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
            </p>
          </div>
        </div>
      </div>

      <div className="location-row">
        <div className="side-wrapper">
          <div className="location-icon-wrapper">
            <SvgIcon icon="location-point" height={16} width={15} />
            <div className="connection-line bottom" />
          </div>
          <div>
            <p className="bold-text">
              {expanded && extendedTripDetails ? `${tripDetails.arrive.AirportName}` : "To"}
            </p>
            <p className="gray-text">
              {expanded && extendedTripDetails
                ? `${extendedTripDetails?.pick_up_details?.drop_off_address?.first_address_line}`
                : `${tripDetails.arrive.AirportName}`}
            </p>
            {expanded && extendedTripDetails && (
              <p className="gray-text">
                {extendedTripDetails?.pick_up_details?.drop_off_address?.city}
              </p>
            )}
          </div>
        </div>

        <div className="right-side-wrapper bottom">
          <div className="time-wrapper">
            <SvgIcon icon="green-clock" width={9} height={10} margin="0 3px 0 0" fill="green" />
            <p className="green-text">
              {moment(tripDetails.arrival_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

VerticalLegConnection.propTypes = {
  tripDetails: PropTypes.instanceOf(Object),
  expanded: PropTypes.bool,
}

VerticalLegConnection.defaultProps = {
  tripDetails: {},
  expanded: false,
}

export default VerticalLegConnection
