import "./CustomerPage.scss"

import moment from "moment"
import React, { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/appSettings"
import { MIDNIGHT_DATETIME } from "../../../constants/dateFormats"
import { getNextTripsPage, getTrips } from "../../../redux/trip/tripActions"
import apiUtils from "../../../utils/api/apiUtils"
import CalendarComponent from "../../atoms/CalendarComponent"
import Loading from "../../atoms/Loading"
import RegularLayout from "../../layouts/RegularLayout"
import CustomerTripRow from "../../molecules/CustomerTripRow"

const CustomerPage = () => {
  const dispatch = useDispatch()
  const [chosenDate, setChosenDate] = useState(moment().format(MIDNIGHT_DATETIME))
  const [offset, setOffset] = useState(0)
  const scrollRef = useRef()

  useEffect(() => {
    setOffset(0)
    dispatch(getTrips({ departure_time__gte: chosenDate }))
  }, [chosenDate])

  const [trips, callsInProgress] = useSelector(({ trip, api }) => [
    trip.userTrips,
    api.callsInProgress,
  ])

  const tripBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIPS, API.GET_NEXT_TRIPS_PAGE], callsInProgress)
  }, [callsInProgress])

  const initialLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIPS], callsInProgress)
  }, [callsInProgress])

  const loadMore = () => {
    if (!tripBusy) {
      const currentOffset = offset + PAGE_LIMIT

      dispatch(getNextTripsPage({ offset: currentOffset, departure_time__gte: chosenDate }))
      setOffset(currentOffset)
    }
  }

  const getPageContent = () =>
    trips && trips.results.length > 0 ? (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!!trips?.next}
        pageStart={0}
        loader={<Loading className="scroll" key={trips?.next} />}
        threshold={100}
        useWindow={false}
      >
        <div className="scroll-content-wrapper">
          {trips &&
            trips.results.map(trip => (
              <CustomerTripRow trip={trip} key={trip.id} scrollRef={scrollRef} />
            ))}
        </div>
      </InfiniteScroll>
    ) : (
      <div className="empty-state-image-wrapper">
        <img
          className="empty-state-image"
          src="./media/customer-empty-state.svg"
          alt="Empty state"
        />
      </div>
    )

  return (
    <RegularLayout withTopMenu withLogout>
      <div className="customer-page-container" ref={scrollRef}>
        <div className="fixed-content">
          <h2 className="page-title">Scheduled rides</h2>

          <CalendarComponent
            value={moment(chosenDate).toDate()}
            onChange={val => setChosenDate(moment(val).format(MIDNIGHT_DATETIME))}
          />

          <div className="breaking-line" />
        </div>

        {initialLoading ? <Loading className="page-content-loading" /> : getPageContent()}
      </div>
    </RegularLayout>
  )
}

CustomerPage.propTypes = {}

CustomerPage.defaultProps = {}

export default CustomerPage
