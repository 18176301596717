import "./Page404.scss"

import React from "react"

const Page404 = () => {
  return (
    <div className="page-404-container">
      <img src="/media/404.svg" className="image-404" alt="Page not found" />
    </div>
  )
}

Page404.propTypes = {}

Page404.defaultProps = {}

export default Page404
