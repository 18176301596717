import PropTypes from "prop-types"
import React from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"

const GuestRoute = ({ children }) => {
  const checkToken = () => {
    const accessToken = localStorage.getItem("accessToken")
    if (!accessToken) {
      return children
    }
    toast.warning("You have to log out first.", {
      toastId: "logout-message",
    })
    return <Navigate to="/" />
  }

  return checkToken()
}

GuestRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GuestRoute
