import "./Modal.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import ReactModal from "react-modal"

const dialogStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 10,
    overflow: "auto",
  },
  content: {
    width: 375,
    maxWidth: "100%",
    position: "static",
    top: "unset",
    left: "unset",
    right: "0",
    bottom: "unset",
    margin: "0 20px",
    overflow: "auto",
    maxHeight: "calc(100vh - 100px)",
    padding: "24px",
  },
}

const Modal = ({
  isOpen,
  style,
  render,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  onRequestClose,
  centered,
  customClass,
}) => {
  const ModalDialogContentClass = classNames(`React-modal--dialog-content ${customClass}`, {
    centered,
  })

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName="React-modal"
      style={{
        content: { ...dialogStyle.content, ...style.content },
        overlay: { ...dialogStyle.overlay, ...style.overlay },
      }}
      ariaHideApp={false}
    >
      <div className={ModalDialogContentClass}>{render && render()}</div>
    </ReactModal>
  )
}

Modal.propTypes = {
  render: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isOpen: PropTypes.bool,
  style: PropTypes.shape({
    overlay: PropTypes.instanceOf(Object),
    content: PropTypes.instanceOf(Object),
  }),
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  centered: PropTypes.bool,
  customClass: PropTypes.string,
}

Modal.defaultProps = {
  render: undefined,
  isOpen: false,
  style: {
    overlay: {},
    content: {},
  },
  shouldCloseOnEsc: false,
  shouldCloseOnOverlayClick: false,
  onRequestClose: () => {},
  centered: true,
  customClass: "",
}

export default Modal
