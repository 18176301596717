import ACTIONS from "../../constants/ACTIONS"

/**
 * Initializes form data
 * @param {Object} formData - initial data for the form
 * @returns {Object} - Action
 */
function initForm(formData = {}) {
  return {
    type: ACTIONS.INIT_FORM,
    formData,
  }
}

/**
 * Clears form data
 * @returns {Object} - Action
 */
function clearForm() {
  return {
    type: ACTIONS.CLEAR_FORM,
  }
}

/**
 * Set errors for form fields
 * @param {Object} formErrors - errors for form fields
 */
function setFormErrors(formErrors) {
  return dispatch => {
    // const timeout = setTimeout(() => {
    dispatch({ type: ACTIONS.CLEAR_FORM_ERRORS })
    //   clearTimeout(timeout)
    // }, 5000)
    dispatch({
      type: ACTIONS.SET_FORM_ERRORS,
      formErrors,
    })
  }
}

/**
 * clear errors for form fields
 */
function clearFormErrors() {
  return dispatch => {
    dispatch({ type: ACTIONS.CLEAR_FORM_ERRORS })
  }
}

/**
 * Updates one form field
 * @param {string} field - name of the field to update
 * @param {string} value - value to update
 * @returns {Object} - Action
 */
function updateFormField(field, value) {
  return {
    type: ACTIONS.UPDATE_FORM_FIELD,
    field,
    value,
  }
}

/**
 * Updates multiple form fields
 * @param {Object} field - form fields to update
 * @returns {Object} - Action
 */
function updateFormFields(fields) {
  return {
    type: ACTIONS.UPDATE_FORM_FIELDS,
    fields,
  }
}
/**
 * Sets loading while form is sending
 * @returns {Boolean} - Action
 */
function formSent() {
  return {
    type: ACTIONS.SET_FORM_SENT,
  }
}
/**
 * resets loading
 * @returns {Boolean} - Action
 */
function formSentReset() {
  return {
    type: ACTIONS.FORM_SENT_RESET,
  }
}
/**
 * Sets response of form
 * @returns {Boolean} - Action
 */
function formResponse() {
  return {
    type: ACTIONS.SET_FORM_RESPONSE,
  }
}

export {
  clearForm,
  clearFormErrors,
  formResponse,
  formSent,
  formSentReset,
  initForm,
  setFormErrors,
  updateFormField,
  updateFormFields,
}
