export const firstCharToUpperCase = string => string.charAt(0).toUpperCase() + string.slice(1)

export const isEmptyObject = obj => {
  if (obj) return Object.keys(obj).length === 0 && obj.constructor === Object
  return undefined
}

export const getFirstChar = string => string.charAt(0)

export default { firstCharToUpperCase, isEmptyObject, getFirstChar }
