import "./DriverInformation.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

import ContactModal from "../ContactModal"
import SvgIcon from "../SvgIcon"
import UserAvatarName from "../UserAvatarName"
import VehicleInformation from "../VehicleInformation"

const DriverInformation = ({ driverInfo, vehicleInfo }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)

  return (
    <div className="driver-information-container" role="button" onClick={e => e.stopPropagation()}>
      <p className="title">Driver information</p>
      {!driverInfo ? (
        <p className="no-driver-text">Driver will be assigned soon!</p>
      ) : (
        <>
          <div className="driver-info-wrapper">
            <UserAvatarName
              firstName={driverInfo?.first_name}
              lastName={driverInfo?.last_name}
              avatar={driverInfo?.avatar}
              avatarSize={23.5}
              avatarFontSize={9}
              nameFontStyle={{ fontSize: 13, fontWeight: 400, color: "#666666" }}
              isDriverInfo
            />
            <div
              className="contact-button"
              role="button"
              onClick={e => {
                e.stopPropagation()
                setContactModalOpen(true)
              }}
            >
              <SvgIcon icon="phone" width={10} height={11} />
              <p className="contact-text">Contact</p>
            </div>
          </div>

          {vehicleInfo && <VehicleInformation vehicleInfo={vehicleInfo} />}
        </>
      )}

      <ContactModal
        isOpen={contactModalOpen}
        setIsOpen={setContactModalOpen}
        phoneNumber={driverInfo?.phone_number}
      />
    </div>
  )
}

DriverInformation.propTypes = {
  driverInfo: PropTypes.instanceOf(Object),
  vehicleInfo: PropTypes.instanceOf(Object),
}

DriverInformation.defaultProps = {
  driverInfo: {},
  vehicleInfo: {},
}

export default DriverInformation
